import React from "react";

// import { Link } from "gatsby";
import { Edge } from "@/types";

import * as styles from "./Feed.module.scss";

type Props = {
  edges: Array<Edge>;
};

const Feed: React.FC<Props> = ({ edges }: Props) => (
  <div className={styles.feed}>
    {edges.map((edge) => (
      <div className={styles.item} key={edge.node.fields.slug}>
        <div className={styles.meta}>
          <span className={styles.date}>
            {/* <Link to={edge.node.fields.categorySlug} className={styles.link}>  -- this is the link to make categories */}
            {edge.node.frontmatter.category}
            {/* </Link> */}
          </span>
        </div>
        <h2 className={styles.title}>
          {/* <Link
            className={styles.link}
            to={edge.node.frontmatter?.slug || edge.node.fields.slug}
          > */}
          {edge.node.frontmatter.title}
          {/* </Link> */}
        </h2>
        <p className={styles.description}>
          {edge.node.frontmatter.description}
        </p>
        {/* <Link -- this is the link to open a new page
          className={styles.more}
          to={edge.node.frontmatter?.slug || edge.node.fields.slug}
        >
          Read
        </Link> */}
      </div>
    ))}
  </div>
);

export default Feed;
